<template>
    <layout-full-width :title="$tc('suite', 2)">
        <component-sub-navigation :items="subNavigation"/>

        <component-actions :action-filters="[]"
                           :itemsPerPage="professionalServices.itemsPerPage"
                           :page="professionalServices.page"
                           :primary-actions="[]"
                           :total="professionalServices.total"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @search="onSearch"/>

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table :class="{'table-hover': !loading.PROFESSIONAL_SERVICES_LIST}" class="table">
                        <thead>
                        <component-table-header :heading-columns="headingColumns"
                                                :select-all-status="getSelectAllStatus()"
                                                :show-checkbox="true"
                                                @sort="onSortProfessionalServices"
                                                @toggleCheckbox="toggleCheckbox"/>
                        </thead>
                        <tbody>
                        <template v-cloak v-if="!loading.PROFESSIONAL_SERVICES_LIST && professionalServices.list.length > 0">
                            <tr v-for="(professionalService, i) in professionalServices.list"
                                :key="'professional-service-' + i">
                                <td class="align-middle">
                                    <button class="btn btn-checkbox" @click="toggleCheckbox(i)">
                                        <font-awesome-icon
                                            :class="professionalService.selected ? 'text-primary' : ''"
                                            :icon="professionalService.selected ? ['fas', 'check-square'] : ['far', 'square']"
                                            class="pe-none"/>
                                    </button>
                                </td>
                                <td class="align-middle">
                                    <font-awesome-icon class="text-primary me-2" :icon="professionalService.icon"/>
                                    <strong>{{ professionalService.suite }}</strong>
                                </td>
                                <td class="align-middle">
                                    <span>{{ professionalService.author }}</span>
                                </td>
                                <td class="align-middle">
                                    <template v-if="professionalService.subscription.active">
                                        <strong>{{professionalService.subscription.name}}</strong>,
                                        <small>
                                            {{ $t('expires-on') }}:
                                            {{professionalService.subscription.expirationDate | date}}
                                        </small>
                                    </template>
                                </td>
                                <td class="align-middle">
                                    <div class="dropdown" v-if="!professionalService.subscription.active">
                                        <button class="btn p-0 bg-transparent"
                                                type="button"
                                                :id="'professional-service-actions-' + i"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false">
                                            <font-awesome-icon icon="ellipsis-h" class="pe-none"/>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right"
                                             :aria-labelledby="'dropdown-' + i">
                                            <a href="https://www.spot-emotion.com/sales"
                                               target="_blank"
                                               class="dropdown-item"
                                               rel="noopener noreferrer">
                                                {{ $t('actions.buy-now') }}
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-cloak v-if="!loading.PROFESSIONAL_SERVICES_LIST && professionalServices.list.length === 0">
                            <tr>
                                <td colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">
                                            {{ $t('pages.suites.no-suites-found') }}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.PROFESSIONAL_SERVICES_LIST">
                            <tr v-for="n in professionalServices.itemsPerPage" :key="'row-shimmer-' + n">
                                <td class="align-middle">
                                    <component-shimmer height="14px" style="margin: 4px 0;" width="14px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer style="margin-bottom: 3px;" width="200px"/>
                                    <component-shimmer/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="60px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="50px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="105px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="20px"/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "../../../layouts/LayoutFullWidth";
import ComponentSubNavigation from "../../../components/ui/ComponentSubNavigation";
import ComponentActions from "../../../components/ui/ComponentActions";
import ComponentTableHeader from "../../../components/ui/ComponentTableHeader";
import ComponentShimmer from "../../../components/ComponentShimmer";

export default {
    name: "PageMain",
    components: {
        ComponentShimmer,
        ComponentTableHeader,
        ComponentActions,
        ComponentSubNavigation,
        LayoutFullWidth
    },
    computed: {
        loading() {
            return {
                PROFESSIONAL_SERVICES_LIST: false
            }
        },
        professionalServices() {
            return {
                page: 1,
                itemsPerPage: 10,
                limit: null,
                total: 5,
                list: [
                    {
                        icon: 'ad',
                        suite: 'Spot Emotion AD',
                        author: 'STINA Business Solutions GmbH',
                        subscription: {
                            active: false,
                            name: null,
                            expirationDate: null
                        }
                    },
                    {
                        icon: 'envelope',
                        suite: 'Spot Emotion LIFT',
                        author: 'STINA Business Solutions GmbH',
                        subscription: {
                            active: true,
                            name: 'Starter Package',
                            expirationDate: '2021-12-31'
                        }
                    },
                    {
                        icon: 'search-dollar',
                        suite: 'Spot Emotion MBOX',
                        author: 'STINA Business Solutions GmbH',
                        subscription: {
                            active: false,
                            name: null,
                            expirationDate: null
                        }
                    },
                    {
                        icon: 'poll-h',
                        suite: 'Spot Emotion SURV',
                        author: 'STINA Business Solutions GmbH',
                        subscription: {
                            active: false,
                            name: null,
                            expirationDate: null
                        }
                    },
                    {
                        icon: 'search-dollar',
                        suite: 'Spot Emotion ZOOM',
                        author: 'STINA Business Solutions GmbH',
                        subscription: {
                            active: false,
                            name: null,
                            expirationDate: null
                        }
                    },
                ]
            }
        }
    },
    data() {
        return {
            subNavigation: [
                {
                    to: {name: 'addons-suites-main'},
                    label: this.$t('main')
                },
                {
                    to: {name: 'addons-suites-additional'},
                    label: this.$t('additional')
                }
            ],
            headingColumns: [
                {
                    title: this.$tc('suite', 1),
                },
                {
                    title: this.$tc('author', 1),
                },
                {
                    title: this.$tc('subscription', 1)
                },
                {
                    title: ""
                }
            ],
            order: {}
        }
    },
    methods: {
        onChangePage() {
            console.log('onChangePage');
        },
        onSearch() {
            console.log('onSearch');
        },
        onChangeItemsPerPage() {
            console.log('onChangeItemsPerPage');
        },
        onApplyFilters() {
            console.log('onApplyFilters');
        },
        onSortProfessionalServices(order) {
            this.order = order;
        },
        async toggleCheckbox(i) {
            if (i === 'ALL') {
                if (this.getSelectAllStatus()) {
                    this.professionalServices.list.forEach(channel => channel.selected = false);
                } else {
                    this.professionalServices.list.forEach(channel => channel.selected = true);
                }
            } else {
                this.professionalServices.list[i].selected = !this.professionalServices.list[i].selected;
            }

            this.$forceUpdate();
            this.getActionButton();
        },
        getSelectAllStatus() {
            const totalCount = this.professionalServices.list.length;
            let count = 0;
            this.professionalServices.list.forEach(channel => {
                if (channel.selected) {
                    count += 1;
                }
            })
            return totalCount === count && totalCount !== 0;
        },
    }
}
</script>

<style lang="scss" scoped></style>